import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles, Grid, Button } from "@material-ui/core";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { metaCellPaths } from "MetaCell/MetaCell";
import ModeAnalysisJobDropdown from "./components/ModeAnalysisJobDropdown/ModeAnalysisJobDropdown";
import ModeAnalysisJobProgress from "./components/ModeAnalysisJobProgress/ModeAnalysisJobProgress";
import ModeAnalysisJobPlots from "./components/ModeAnalysisJobPlots/ModeAnalysisJobPlots";
import JobActions from "components/JobActions/JobActions";
import SimulationApi from "MetaCell/api/Simulation";
import DirectoryExplorerSelector from "MetaCell/selectors/DirectoryExplorer";
import SimulationSelector from "MetaCell/selectors/Simulation";
import debounce from "lodash.debounce";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import JsonDialog from "components/JsonDialog/JsonDialog";
import SimulationSettingsSelector from "MetaCell/selectors/SimulationSettings";
import { isEqual } from "lodash";
import SweepPoint from "components/SweepPoint/SweepPoint";
import Probe from "./components/Probe/Probe";
import SimulationSettingsApi from "MetaCell/api/SimulationSettings";
import OpenSimulationSideView from "MetaCell/components/OpenSimulationSideView/OpenSimulationSideView";
import UserSelector from "BaseApp/selectors/User";
import { SimulationType } from "MetaCell/helper/SimulationResult";
import HiddenMaterialGraphs from "MetaCell/containers/SimulateCanvas/components/HiddenMaterialGraphs";
import SimulationResultHelper from "MetaCell/helper/SimulationResult";
import SimulationAction from "MetaCell/actions/Simulation";
import HelperUtils from "MetaCell/helper/HelperUtils";
import DirectionSnackbar from "components/Snackbar/Snackbar";

export const styles = theme => ({
  buttonWrapper: {
    position: "relative",
    marginTop: 20
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    paddingTop: 50
  },
  left: {
    paddingRight: 260
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
});

/**
 * A component created to be the content for the mode analysis simulation.
 * @author Akira Kotsugai
 * @param {Object} props - the props passed by parent components
 */
export class ModeAnalysisCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      polling: true,
      jsonToShow: null,
      sweptVariableValues: {},
      configuration: null
    };
  }

  /**
   * it sets the open page for this component as soon as the component mounts
   * fetches the jobs and starts the polling
   * and set the sweep point in the state to the first value of all variables
   */
  componentDidMount() {
    this.props.setPage(metaCellPaths.MODE_ANALYSIS);
    this.setSelectedValues();
    this.poll();
  }

  /**
   * it triggers the polling whenever a job is selected and updates the selected values when
   * the sweep variables change
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    const jobHasBeenSelected =
      prevProps.selectedJobId !== this.props.selectedJobId;
    if (jobHasBeenSelected) {
      this.poll();
    }

    const sweptVariablesChanged = !isEqual(
      prevProps.sweptVariables,
      this.props.sweptVariables
    );
    const oldProbe = this.getProbe(prevProps.probes);
    const newProbe = this.getProbe(this.props.probes);
    const probePositionChanged =
      oldProbe && newProbe && oldProbe.position !== newProbe.position;
    if (sweptVariablesChanged || probePositionChanged) {
      this.setSelectedValues();
    }
  }

  /**
   * it triggers the poll if there is a job selected otherwise it is deactivated
   */
  poll() {
    const { selectedJobId } = this.props;
    this.setState({ polling: selectedJobId !== null }, () =>
      this.getJobProgress()
    );
  }

  /**
   * it gets the job status from the backend endpoint and depending on the response
   * it decides whether it should call itself again, but with delay for the next call.
   */
  async getJobProgress() {
    const { polling } = this.state,
      { selectedJobId, getJobProgressAction } = this.props;
    if (selectedJobId) {
      try {
        const data = await getJobProgressAction(selectedJobId);
        if (
          data.status === "ERROR" ||
          data.status === "DONE" ||
          data.status === "STOPPED" ||
          data.status === "FAILED"
        ) {
          this.setState({ polling: false });
          await this.getConfiguration(selectedJobId);
        } else if (polling) {
          this.getJobProgressWithDelay();
        }
      } catch (exception) {
        if (polling) {
          this.setState({ polling: false });
        }
      }
    } else {
      this.setState({ polling: false });
    }
  }

  /**
   * it's not a component method, but an object. it calls the status getter with a 5s delay
   * when it is invoked.
   */
  getJobProgressWithDelay = debounce(() => {
    this.getJobProgress();
  }, 5000);

  /**
   * @returns {Object[]} the list of mode analysis jobs
   */
  getModeAnalysisJobsList = () => {
    const { jobs } = this.props;
    return jobs ? Object.values(jobs.byId) : [];
  };

  warn(errorMessage) {
    this.setState({ errorMessage: null }, () =>
      this.setState({ errorMessage })
    );
  }

  /**
   * it opens a confirm dialog of which the action creates a new mode analysis job
   */
  showNewJobDialog = () => {
    const { showConfirmDialog, runModeAnalysis, simulationId } = this.props;
    const title = "New Mode Analysis";
    const message = `Are you sure you want to start a new mode analysis?`;
    const confirmAction = () => {
      return runModeAnalysis(
        simulationId,
        this.state.sweptVariableValues
      ).catch(error =>
        this.warn(error.response.data.errors.get_simulation_params)
      );
    };
    showConfirmDialog(title, message, confirmAction, undefined, false);
  };

  hideJsonDialog = () => {
    this.setState({ jsonToShow: null });
  };

  /**
   * it opens the json dialog with errors of the mode analysis jobs
   * @param {Object} errors - the errors
   */
  onShowResultsErrorsAndWarnings = (errors, warnings) => {
    this.setState({
      jsonToShow: {
        errors,
        warnings
      }
    });
  };

  /**
   * @returns {Object[]} variables that belong to the open simulation
   */
  getSimulationSweptVariables() {
    const { simulationId, sweptVariables } = this.props;
    if (sweptVariables) {
      return sweptVariables.filter(
        variable => variable.simulation === simulationId
      );
    }
    return [];
  }

  /**
   * @returns {Object[]} - only variables belonging to the simulation that are assigned
   * somewhere in the simulation except the one assigned in the probe position if there is one
   * in a shape supported by the sweep point component
   */
  getSweptVariables() {
    const { probes, store } = this.props;
    const simSweptVariables = this.getSimulationSweptVariables();
    const simVarNames = simSweptVariables
      .filter(sv => sv.sweepType != "Formula")
      .map(({ variableName }) => variableName);
    const simulationVariablesExceptProbe = simSweptVariables.filter(
      variable => {
        const probePosition = this.getProbe(probes).position;
        if (isNaN(probePosition)) {
          const varAssignmentOrMathExpression = probePosition.substring(1);
          const probePositionVarName = HelperUtils.extractVariableName(
            varAssignmentOrMathExpression,
            simVarNames
          );
          return variable.variableName !== probePositionVarName;
        }
        return true;
      }
    );
    const unusedVariablesNames = HelperUtils.getUnusedSweepVariableNames(
      store,
      simulationVariablesExceptProbe
    );
    const usedSweepVariables = simulationVariablesExceptProbe.filter(
      variable =>
        !unusedVariablesNames.includes(variable.variableName) &&
        variable.sweepType != "Formula"
    );
    return usedSweepVariables.map(variable => {
      let values = [];
      if (variable.sweepType === "Optimization") {
        let parameters = variable.parameters.split(/[:;]/);
        values = [parseFloat(parameters[2])];
      } else {
        values = JSON.parse(variable.values);
      }
      return {
        name: variable.variableName,
        values
      };
    });
  }

  /**
   * @returns {Object[]} only variables not used by the simulation except the probe position
   */
  getProbeAvailableVariables() {
    const { store, probes } = this.props;
    const simulationVariables = this.getSimulationSweptVariables();
    const simulationVariablesExceptProbe = this.getSimulationSweptVariables().filter(
      variable =>
        variable.variableName !== this.getProbe(probes).position.substring(1)
    );
    const unusedVariablesNames = HelperUtils.getUnusedSweepVariableNames(
      store,
      simulationVariablesExceptProbe
    );
    const usedSweepVariables = simulationVariablesExceptProbe.filter(
      variable => !unusedVariablesNames.includes(variable.variableName)
    );
    const usedSweepVariableIds = usedSweepVariables.map(
      variable => variable.id
    );
    const independentSweepVariables = simulationVariables.filter(
      sv => sv.sweepType != "Formula"
    );
    return independentSweepVariables.filter(
      variable => !usedSweepVariableIds.includes(variable.id)
    );
  }

  /**
   * sets in the state the first value of each swept variable
   */
  setSelectedValues() {
    let sweptVariableValues = { ...this.state.sweptVariableValues };
    for (const variable of this.getSweptVariables()) {
      if (!sweptVariableValues.hasOwnProperty(variable.name)) {
        sweptVariableValues[variable.name] = variable.values[0];
      }
    }
    const variableNames = this.getSweptVariables().map(
      variable => variable.name
    );
    for (const variableName of Object.keys(sweptVariableValues)) {
      if (!variableNames.includes(variableName)) {
        delete sweptVariableValues[variableName];
      }
    }
    this.setState({ sweptVariableValues });
  }

  /**
   * it updates a variable's selected value in the state
   * @param {*} variableName - the variable to update the selected value
   * @param {*} value - the new selected value
   */
  setSelectedValue = sweptVariableValues => {
    this.setState({ sweptVariableValues });
  };

  /**
   * @param {Object} probes - object containing all probes
   * @returns {Object} the probe of the open simulation
   */
  getProbe(probes) {
    const { simulationId } = this.props;
    return simulationId
      ? probes.find(probe => probe.simulation === simulationId)
      : null;
  }

  /**
   * @callback
   * @param {*} probeChanges
   */
  handleProbeChange = probeChanges => {
    const probe = this.getProbe(this.props.probes);
    if (probe) {
      this.props.updateProbe(probe.id, probeChanges);
    }
  };

  getSideViewProbePosition(position) {
    if (isNaN(position)) {
      try {
        const varAssignmentOrMathExpression = position.substring(1);
        const varName = HelperUtils.extractVariableName(
          varAssignmentOrMathExpression,
          this.props.sweptVariables.map(({ variableName }) => variableName)
        );
        const variable = this.props.sweptVariables.find(
          ({ variableName }) => variableName === varName
        );
        const smallestValue = JSON.parse(variable.values)[0];
        return HelperUtils.resolveMathExpression(
          varAssignmentOrMathExpression,
          { [varName]: smallestValue }
        );
      } catch {
        return 0;
      }
    }
    return position;
  }

  getSimulationName(job) {
    if (job) {
      const { simulations } = this.props;
      const simulation = simulations.byId[job.simulation];
      if (simulation) {
        return simulation.name;
      }
    }
    return "";
  }

  getProjectName(job) {
    if (job) {
      const { simulations, projects } = this.props;
      const simulation = simulations.byId[job.simulation];
      if (simulation) {
        const project = projects.byId[simulation.project];
        if (project) {
          return project.name;
        }
      }
    }
    return "";
  }

  async getConfiguration(jobId) {
    if (jobId) {
      const jobDetails = await SimulationApi.getJobDetails(jobId);
      const { configuration } = jobDetails.data;
      this.setState({ configuration });
    }
    return null;
  }

  async getJobPlotCrossSection(plotId, orientation, index) {
    const result = await SimulationApi.getJobPlotCrossSection(
      plotId,
      orientation,
      index
    );
    return result?.data;
  }

  addPlotbox = async () => {
    await this.props.addPlotbox(SimulationApi.addProbeJobPlot);
    this.forcePlotboxManagerUpdate();
  };

  forcePlotboxManagerUpdate = () => {
    const { selectedJobId, selectJob } = this.props;
    setTimeout(() => {
      selectJob(null);
      selectJob(selectedJobId);
    }, 500);
  };

  /**
   * it stops a running analysis job
   * @param {Number} jobId - the job id
   */
  stopModeAnalysisJob = jobId => {
    return SimulationApi.stopSimulationJob(jobId).then(response => {
      this.poll();
      return Promise.resolve();
    });
  };

  render() {
    const { jsonToShow } = this.state;
    const { classes, selectedJobId, jobs, probes } = this.props;
    const selectedJob = selectedJobId ? jobs.byId[selectedJobId] : null;
    const probe = this.getProbe(probes);
    return probe ? (
      <div>
        <div className={classes.wrapper}>
          {this.state.polling && (
            <ModeAnalysisJobProgress
              selectedJob={selectedJob}
              stopJob={this.stopModeAnalysisJob}
            />
          )}
          {!this.state.polling && (
            <div style={{ width: "100%", paddingLeft: 20 }}>
              <div className={classes.left}>
                <Grid container style={{ width: "100%" }} spacing={3}>
                  <Grid item xs={5}>
                    <Grid container spacing={5}>
                      <Grid item xs={10}>
                        <Probe
                          name={probe.name}
                          position={probe.position}
                          updateFieldCallback={this.handleProbeChange}
                          sweptVariables={this.getProbeAvailableVariables()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SweepPoint
                          sweptVariables={this.getSweptVariables()}
                          sweptVariableValues={this.state.sweptVariableValues}
                          setSelectedValue={this.setSelectedValue}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={7} id="sideView">
                    <div style={{ width: "50%", margin: "auto" }}>
                      <OpenSimulationSideView
                        title={"Probe position on cell side view"}
                        probePosition={this.getSideViewProbePosition(
                          probe.position
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ModeAnalysisJobPlots
                      plotboxes={this.props.plotboxes}
                      deletePlotbox={this.props.deletePlotbox}
                      selectedJob={selectedJob}
                      onPlotBoxChange={this.props.onPlotBoxChange}
                      onRangeValueChange={this.props.onRangeValueChange}
                      showErrorsAndWarnings={
                        this.onShowResultsErrorsAndWarnings
                      }
                      configuration={this.state.configuration}
                      simulationName={this.getSimulationName(selectedJob)}
                      projectName={this.getProjectName(selectedJob)}
                      username={this.props.user.username}
                      handleCrossSection={this.getJobPlotCrossSection}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.right}>
                <ModeAnalysisJobDropdown
                  selectedJobId={selectedJobId}
                  jobs={this.getModeAnalysisJobsList()}
                />
                <JobActions
                  exportSimulation={
                    selectedJob && selectedJob.status !== "FAILED"
                  }
                  simulationName={this.getSimulationName(selectedJob)}
                  projectName={this.getProjectName(selectedJob)}
                  username={this.props.user.username}
                  simulationJobId={selectedJob && selectedJob.id}
                  configuration={this.state.configuration}
                  jobType={SimulationType.MODE_ANALYSIS}
                  onNewJob={this.showNewJobDialog}
                  showResultsErrorsAndWarnings={
                    selectedJob &&
                    (selectedJob.errors || selectedJob.warnings) &&
                    (() =>
                      this.onShowResultsErrorsAndWarnings(
                        selectedJob.errors,
                        selectedJob.warnings
                      ))
                  }
                  exportPlotReport={
                    SimulationResultHelper.buildAndDownloadModeAnalysisPlotsReport
                  }
                  exportConfigurationReport={
                    SimulationResultHelper.buildAndDownloadModeAnalysisConfigurationReport
                  }
                  exportFullReport={
                    SimulationResultHelper.buildAndDownloadModeAnalysisFullReport
                  }
                  errors={selectedJob?.errors}
                  warnings={selectedJob?.warnings}
                />
                {selectedJob && (
                  <Button
                    name="AddPlotButton"
                    variant="contained"
                    color="primary"
                    onClick={() => this.addPlotbox()}
                    test-data="add-plot"
                    disabled={
                      selectedJob.status === "FAILED" ||
                      selectedJob.status === "STOPPED"
                    }
                  >
                    Add plot
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        <JsonDialog
          open={jsonToShow !== null}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />
        <HiddenMaterialGraphs configuration={this.state.configuration} />
        {this.state.errorMessage && (
          <DirectionSnackbar message={this.state.errorMessage} />
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    simulationId: DirectoryExplorerSelector.getSimulationOpenId(state),
    jobs: SimulationSelector.getModeAnalysisJobs(state),
    selectedJobId: SimulationSelector.getSelectedModeAnalysisJobId(state),
    sweptVariables: SimulationSettingsSelector.getSweptVariables(state),
    probes: SimulationSettingsSelector.getFieldProbes(state),
    simulations: DirectoryExplorerSelector.getSimulations(state),
    projects: DirectoryExplorerSelector.getProjects(state),
    user: UserSelector.getUser(state),
    store: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProbe: (id, props) =>
      dispatch(SimulationSettingsApi.patchProbe(id, props)),
    getJobProgressAction: jobId =>
      dispatch(SimulationApi.getTaskResultStatus(jobId, true)),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    runModeAnalysis: (simulationId, sweepPoint) =>
      dispatch(SimulationApi.runModeAnalysis(simulationId, sweepPoint)),
    selectJob: jobId => dispatch(SimulationAction.selectModeAnalysisJob(jobId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(ModeAnalysisCanvas)));
